import { template as template_18ab5c0b89e64af1bf3148ef841d0009 } from "@ember/template-compiler";
import { element } from 'ember-element-helper';
import ModuleElement from './module-element';
export default class ModulixCustomElement extends ModuleElement {
    static{
        template_18ab5c0b89e64af1bf3148ef841d0009(`
    <div class="element-custom">
      {{#let (element @component.tagName) as |Tag|}}
        <Tag props={{@component.props}} />
      {{/let}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
