import { template as template_f89c658953e84f4c8e06a9c4bc6a9fdb } from "@ember/template-compiler";
export default template_f89c658953e84f4c8e06a9c4bc6a9fdb(`
  <ul class="responsive-list-wide-wrap">
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
